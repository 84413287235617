import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Admin } from './pages/admin';
import { Tattoo } from './pages/tattoo';

function App() {
    return (
        <div className="text-neutral-50 bg-neutral-900">
            <Router>
                <Routes>
                    <Route path="/tattoo" element={<Tattoo />} />
                    <Route path="/admin" element={<Admin />} />
                </Routes>
            </Router>
        </div>
    );
}
export default App;
