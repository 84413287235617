import React, { Component } from 'react';
import { utils } from '../utils/api-utils';

let value: string;

function setUrl() {
    utils.setUrl(value);
    // console.log(value);
}

export class Url extends Component {
    render() {
        return (
            <div>
                <input
                    type="text"
                    className="text-black"
                    onChange={(evt) => {
                        value = evt.target.value;
                    }}
                />
                <button onClick={setUrl}> Add Url</button>
            </div>
        );
    }
}
