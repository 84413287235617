import { Component } from 'react';
import { utils } from '../utils/api-utils';
export class Upload extends Component {
    render() {
        return (
            <form
                id="uploadForm"
                action="http://10.0.0.37:3001/upload"
                method="post"
                encType="multipart/form-data"
            >
                <input type="file" name="sampleFile" />
                <input
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onSubmit={utils.submitFile}
                    value="Upload!"
                />
            </form>
        );
    }
}
