import { Data, FileUpload } from '../models/data';

const domain = 'https://api.keneldridge.com';

export namespace utils {
    export const getUploads = async (includeDeleted?: boolean): Promise<FileUpload[]> => {
        const response = await fetch(`${domain}/getUploads`);
        let uploads = await response.json();
        if (!includeDeleted) {
            uploads = uploads.filter((a: FileUpload) => a.isDeleted !== true);
        }
        return uploads;
    };

    export const getData = async (includeDeleted?: boolean): Promise<Data> => {
        const response = await fetch(`${domain}/getData`);
        const data = await response.json();
        if (!includeDeleted) {
            data.uploads = data.uploads.filter((a: FileUpload) => a.isDeleted !== true);
        }
        return data;
    };

    export const login = async (pwd: string) => {
        await fetch(`${domain}/login?pwd=${pwd}`);
    };

    export const logoff = async () => {
        await fetch(`${domain}/logoff`);
    };

    export const submitFile = (event: any) => {
        event.preventDefault();
        event.stopPropigation();
    };

    export const setData = async (data: Data) => {
        const rawResponse = await fetch(`${domain}/setData`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    };

    export const getSelected = async () => {
        const response = await fetch(`${domain}/getSelected`);
        const data = await response.json();
        return data;
    };

    export const setUrl = async (url: string) => {
        const response = await fetch(`${domain}/setUrl?url=${url}`);
        const data = await response.json();
        return data;
    };
}
