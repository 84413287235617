import React, { Component, useEffect, useState } from 'react';
import { utils } from '../utils/api-utils';
export class Login extends Component {
    render() {
        return (
            <div>
                <button onClick={() => utils.login('122333444455555')} className="p-6">
                    Login
                </button>
                <button onClick={utils.logoff} className="p-6">
                    Logoff
                </button>
            </div>
        );
    }
}
