import React, { Component, useEffect, useState } from 'react';
import { utils } from '../utils/api-utils';
import { FileUpload } from '../models/data';

export function Tattoo() {
    const [selectedUpload, setSelected] = useState<FileUpload>();

    useEffect(() => {
        utils.getSelected().then((selected) => {
            setSelected(selected);
            console.log(selected);
        });
    }, []);

    if (selectedUpload?.isRedirect) {
        window.location.href = selectedUpload?.url;
    }

    return (
        <div>
            <img src={'../uploads/' + selectedUpload?.fileName}></img>
        </div>
    );
}
