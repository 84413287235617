import React, { Component, useEffect, useState } from 'react';
import { utils } from '../utils/api-utils';
import { Login } from '../components/login';
import { Upload } from '../components/upload';
import { Url } from '../components/url';
import { FileUpload } from '../models/data';

let time: number;
let position: number;
let updateUploads: any;

const touchStart = (event: any) => {
    position = event.touches[0].clientX;
    time = Date.now();
};

const touchEnd = (event: any, id: number) => {
    if (position - event.changedTouches[0].clientX > 100) {
        utils.getData(true).then((data) => {
            data.uploads.forEach((a) => {
                if (a.dateCreated === id && !a.isSelected) {
                    a.isDeleted = true;
                }
                return a;
            });
            utils.setData(data);
            setTimeout(() => {
                utils.getUploads().then((uploads) => {
                    updateUploads(uploads);
                });
            }, 100);
        });
        return;
    }

    //set selected
    const diff = Date.now() - time;
    if (diff >= 10) {
        utils.getData(true).then((data) => {
            data.uploads.forEach((a) => {
                if (a.dateCreated === id) {
                    a.isSelected = true;
                    a.timesSelected++;
                } else {
                    a.isSelected = false;
                }
                return a;
            });
            utils.setData(data);
            setTimeout(() => {
                utils.getUploads().then((uploads) => {
                    updateUploads(uploads);
                });
            }, 100);
        });
        return;
    }
};

export function Admin() {
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    updateUploads = setUploads;
    return (
        <div>
            <Login />
            <button onClick={utils.getSelected} className="p-6">
                Get Selected
            </button>
            <button
                onClick={() =>
                    utils.getUploads().then((uploads: FileUpload[]) => setUploads(uploads))
                }
                className="p-6"
            >
                Show Uploads
            </button>
            <Upload />
            <Url />
            <ul>
                {uploads?.map((upload: FileUpload) => (
                    <li key={upload.dateCreated}>
                        <div className="flex flex-wrap justify-center">
                            <div className={'w-3/4 px-4'}>
                                {upload.isRedirect ? (
                                    <div
                                        onTouchStart={touchStart}
                                        onTouchEnd={(event) => touchEnd(event, upload.dateCreated)}
                                        className={`p-2 rounded-3xl max-w-full h-auto align-middle ${
                                            upload.isSelected
                                                ? 'drop-shadow-2xl border-white border-8'
                                                : ''
                                        }`}
                                    >
                                        {upload.url}
                                    </div>
                                ) : (
                                    <img
                                        onTouchStart={touchStart}
                                        onTouchEnd={(event) => touchEnd(event, upload.dateCreated)}
                                        src={`uploads/${upload.fileName}`}
                                        className={`p-2 rounded-3xl flex flex-row justify-center items-center  ${
                                            upload.isSelected
                                                ? 'drop-shadow-2xl border-white border-8'
                                                : ''
                                        }`}
                                    />
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
